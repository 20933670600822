h1 {
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 1.5rem;
  margin-top: 0;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 0;
}

h3 {
  font-size: 1.25rem;
  font-weight: 450;
  margin-bottom: 0.625rem;
  margin-top: 0;
}

h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

h5 {
  font-size: 0.875rem;
  font-weight: 200;
  margin-bottom: 0.4375rem;
  margin-top: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
